import React from "react";
import ReactsHubspotForm from 'react-hubspot-form';

const HubspotForm = (props) => (
    <ReactsHubspotForm
        region="eu1"
        portalId="139695046"
        {...props}
    />
);

export default HubspotForm;