import React, { useState } from "react";
import { graphql, navigate } from "gatsby";
import { getSrc } from "gatsby-plugin-image";
import { isSameDay, format } from "date-fns";
import { useSpring, animated } from 'react-spring';

import Layout from "../components/layout/Layout";
import { Button, SimpleButtonSymbolHover, ButtonSymbolHover } from "../components/Button";
import { PageLinkList } from "../components/PageLinkList";
import ContentHTMLWithHoverPopUps from "../components/ContentHTMLWithHoverPopUps";
import ShareButtons from "../components/ShareButtons";
import VimeoPlayer from "../components/VimeoPlayer";
import HubspotForm from "../components/HubspotForm";

import grey_small_circle from "../assets/images/grey_small_circle.svg";
import grey_small_cylinder from "../assets/images/grey_small_cylinder.svg";
import grey_small_rectangle from "../assets/images/grey_small_rectangle.svg";
import grey_small_square_rotated from "../assets/images/grey_small_square_rotated.svg";
import grey_small_transcript from "../assets/images/grey_small_transcript.svg";
import white_transparent_blockquote_start from "../assets/images/white_transparent_blockquote_start.svg";
import white_transparent_blockquote_end from "../assets/images/white_transparent_blockquote_end.svg";
import white_chevron from "../assets/images/white_chevron.svg";

import { pushToGTM, getDefinedTermPopUpItem, postFBEvent } from "../helpers";

export default ({
    data,
    pageContext,
    location
}) => {
    const [isRegistrationOpen, setIsRegistrationOpen] = React.useState(false);
    const spring = useSpring({ paddingBottom: "50px", opacity: isRegistrationOpen ? 1 : 0, transform: isRegistrationOpen ? "translateY(0px)" : "translateY(1000px)" });

    const ctaActions = [
        {
            text: "Register",
            description: "secure your spot!"
        },
        {
            text: "Watch the event",
            description: "watch!"
        }
    ];

    const {
        startTime,
        endTime,
        location: physicalLocation,
        speakers,
        recordingVimeoUrl,
        registrationHubspotFormId,
        registrationExternalFormUrl,
        ctaText,
        overview,
        agendaList,
        freeText,
        quotes,
        image,
        url,
        createdAt,
        updatedAt,
        metaTags,
        pageLinkLists,
        pageLinkListHeader
    } = data.contentfulEvent;

    const agendaImages = [grey_small_circle, grey_small_cylinder, grey_small_rectangle, grey_small_square_rotated, grey_small_transcript];
    const getAgendaImage = (i) => agendaImages[i] ? agendaImages[i] : agendaImages[Math.floor(Math.random() * agendaImages.length)];
    const toggleCatalogue = () => setIsRegistrationOpen(isRegistrationOpen => !isRegistrationOpen);

    const seoData = {
        updatedAt: updatedAt,
        createdAt: createdAt,
        physicalLocation: physicalLocation,
        author: 'Frends',
        image: image && image.file.url,
        url: url,
        metaTags: metaTags,
        startTime: startTime,
        endTime: endTime
    }

    const handleSubmit = () => {
        pushToGTM({
            'event': 'frends_form_submit',
            'form_type': 'Event Form Submit',
            'resource_name': 'Event registration'
        });

        if (typeof window !== "undefined") {
            if (window.fbq != null) {
                window.fbq('track', 'CompleteRegistration')
            }
        }

        postFBEvent("CompleteRegistration", "Event Form");

        navigate("/thanks-for-the-registration/");
    }

    const pushVideoPlayEventToGTM = (e) => {
        pushToGTM({
            'event': 'video_play_start',
            'video_name': pageContext.title,
            'duration': e.duration,
            'playback_duration_s': e.seconds,
            'playback_duration_percent': e.percent,
        });
    }

    const pushVideoStopEventToGTM = (e) => {
        pushToGTM({
            'event': 'video_play_end',
            'video_name': pageContext.title,
            'duration': e.duration,
            'playback_duration_s': e.seconds,
            'playback_duration_percent': e.percent,
        });
    }

    const pushVideoPlayingEventToGTM = (e) => {
        pushToGTM({
            'event': 'video_playing',
            'video_name': pageContext.title,
            'duration': e.duration,
            'playback_duration_s': e.seconds,
            'playback_duration_percent': e.percent,
        });
    }
    const startTimeAsDate = new Date(startTime);
    const endTimeAsDate = new Date(endTime);

    const date = isSameDay(startTimeAsDate, endTimeAsDate)
        ? format(startTimeAsDate, "dd.MM")
        : `${format(startTimeAsDate, "dd.MM")} - ${format(endTimeAsDate, "dd.MM")}`;

    const [mobilePlayerShown, setMobilePlayerShown] = useState(false);
    const handleMobileWatchVideoButtonClick = () => setMobilePlayerShown(cur => !cur);

    const definedTermsPopUpItems = data.allContentfulDefinedTerm.nodes.map(getDefinedTermPopUpItem);

    const getMobileCTAContainer = () => {
        if (recordingVimeoUrl) {
            return (
                <nav
                    className="webinar-mobile-watch-container is-hidden-tablet"
                    role="navigation"
                    aria-label="watch the event"
                >
                    <div className="webinar-mobile-watch-title">
                        <p className="is-white size-24">
                            {ctaText.toLowerCase()}
                        </p>

                        <SimpleButtonSymbolHover
                            type="outlined"
                            color="white"
                            text={mobilePlayerShown ? 'Hide' : ctaActions.find((x) => x.text.toLowerCase() === ctaText.toLowerCase()).description}
                            onClick={() => handleMobileWatchVideoButtonClick()}
                        />
                    </div>

                    {mobilePlayerShown && (
                        <VimeoPlayer
                            url={recordingVimeoUrl}
                            play={mobilePlayerShown}
                            onPlay={() => pushVideoPlayEventToGTM()}
                            onStop={(e) => pushVideoStopEventToGTM(e)}
                            onPlaying={(d) => pushVideoPlayingEventToGTM(d)}
                        />
                    )}
                </nav>
            );
        }

        if (registrationHubspotFormId) {
            return (
                <div className="mobile-platform-catalogue-container is-hidden-tablet">
                    <animated.div style={spring} className="platform-menu webinar">
                        <HubspotForm
                            formId={registrationHubspotFormId}
                            loading={<div>Loading...</div>}
                            onSubmit={handleSubmit}
                        />
                    </animated.div>

                    <nav
                        className="navbar mobile-platform-catalogue webinar"
                        onClick={toggleCatalogue}
                        role="navigation"
                        aria-label="registration"
                    >
                        <div className="navbar-item">
                            <p className="is-white size-24" style={{ marginLeft: '40px' }}>
                                registration
                            </p>
                        </div>
                        <div className="navbar-item chevron">
                            <img
                                src={white_chevron}
                                style={{ verticalAlign: "middle", transform: isRegistrationOpen ? "rotate(0deg)" : "rotate(180deg)", transition: "all .5s ease" }}
                            />
                        </div>
                    </nav>
                </div >
            );
        }

        if (registrationExternalFormUrl) {
            return (
                <nav
                    className="webinar-mobile-watch-container is-hidden-tablet"
                    role="navigation"
                    aria-label="register to event"
                >
                    <div className="webinar-mobile-watch-title">
                        <p className="is-white size-24">
                            {ctaText.toLowerCase()}
                        </p>

                        <ButtonSymbolHover
                            outerLink
                            color="white"
                            text={ctaActions.find((x) => x.text.toLowerCase() === ctaText.toLowerCase()).description}
                            to={registrationExternalFormUrl}
                        />
                    </div>
                </nav>
            );
        }

        return <></>
    };

    const getDesktopCTAContainer = () => {
        if (recordingVimeoUrl) {
            return (
                <div className="column is-hidden-mobile">
                    <div className="card is-white-bg sticky-card is-card-shadow" style={{ marginTop: "-140px" }}>
                        <div className="card-content">
                            <div className="has-text-centered">
                                <h2 className="font-variable is-transparent margin-bottom-20 text-stroke-black size-50">
                                    {ctaText.toLowerCase()}
                                </h2>

                                <VimeoPlayer
                                    url={recordingVimeoUrl}
                                    onPlay={(e) => pushVideoPlayEventToGTM(e)}
                                    onStop={(e) => pushVideoStopEventToGTM(e)}
                                    onPlaying={(d) => pushVideoPlayingEventToGTM(d)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (registrationHubspotFormId) {
            return (
                <div className="column is-hidden-mobile">
                    <div className="card is-white-bg sticky-card is-card-shadow" style={{ marginTop: "-140px" }}>
                        <div className="card-content">
                            <HubspotForm
                                formId={registrationHubspotFormId}
                                loading={<div>Loading...</div>}
                                onSubmit={handleSubmit}
                            />
                        </div>
                    </div>
                </div>
            );
        }

        if (registrationExternalFormUrl) {
            return (
                <div className="column is-hidden-mobile">
                    <div className="card is-white-bg sticky-card is-card-shadow" style={{ marginTop: "-140px" }}>
                        <div className="card-content">
                            <div className="has-text-centered">
                                <h2 className="font-variable is-transparent margin-bottom-20 text-stroke-black size-50">
                                    {ctaActions.find((x) => x.text.toLowerCase() === ctaText.toLowerCase()).description}
                                </h2>

                                <ButtonSymbolHover
                                    outerLink
                                    color="purple"
                                    text={ctaText.toLowerCase()}
                                    to={registrationExternalFormUrl}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return <></>;
    };


    return (
        <Layout
            title={pageContext.title}
            pageContentId="event-content"
            isEvent={true}
            transparentNavigation={true}
            seoData={seoData}
            location={location}
        >
            {getMobileCTAContainer()}

            <main id="event-content" style={{ marginTop: "-80px" }}>
                <section className="hero is-medium is-hidden-mobile" style={{ background: `url(${getSrc(data.bg)}) no-repeat center/cover` }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-6">
                                    <h2 className="font-bold size-170 is-transparent text-stroke-white">
                                        {date}
                                    </h2>
                                </div>
                                <div className="column is-6">
                                    <h1 className="size-50 is-white">
                                        {pageContext.title}
                                    </h1>
                                    <p className="size-28 is-purple-tertiary">
                                        {format(startTimeAsDate, "HH:mm")}-{format(endTimeAsDate, "HH:mm")} {format(startTimeAsDate, 'z')} {physicalLocation}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="hero is-large is-hidden-tablet" style={{ background: `url(${getSrc(data.bg)}) no-repeat center/cover` }}>
                    <div className="hero-body">
                        <div className="container">
                            <div className="columns is-vcentered">
                                <div className="column is-6">
                                    <h2 className="font-bold size-170 is-transparent text-stroke-white">
                                        {date}
                                    </h2>
                                </div>
                                <div className="column is-6">
                                    <h1 className="size-50 is-white">
                                        {pageContext.title}
                                    </h1>
                                    <p className="size-28 is-purple-tertiary">
                                        {format(startTimeAsDate, "HH:mm")}-{format(endTimeAsDate, "HH:mm")} {format(startTimeAsDate, 'z')} {physicalLocation}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="section bg-grey">
                    <div className="container">
                        <div className="columns">
                            <div className="column">
                                {speakers && (
                                    <>
                                        <h2 className="font-variable size-56 is-transparent text-stroke-black">
                                            speakers
                                        </h2>

                                        {speakers.map((speaker, i) => (
                                            <div className="columns is-vcentered is-mobile is-multiline" key={i}>
                                                {speaker.linkedInPage ? (
                                                    <>
                                                        <div className="column is-2">
                                                            <img src={getSrc(speaker.portrait)} style={{ borderRadius: "50px" }} alt={speaker.name} width="90" height="90" />
                                                        </div>
                                                        <div className="column is-10">
                                                            <a href={speaker.linkedInPage} target="_blank">
                                                                <span className="is-black size-16">{speaker.name}</span><br />
                                                                <span className="is-grey-1 size-14">{speaker.title}</span>
                                                            </a>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <>
                                                        <div className="column is-2">
                                                            <img src={getSrc(speaker.portrait)} style={{ borderRadius: "50px" }} alt={speaker.name} width="90" height="90" />
                                                        </div>
                                                        <div className="column is-10">
                                                            <span className="is-black size-16">{speaker.name}</span><br />
                                                            <span className="is-grey-1 size-14">{speaker.title}</span>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                        ))}

                                    </>

                                )}
                                <h2 className="font-variable size-56 is-transparent text-stroke-black">
                                    overview
                                </h2>
                                <ContentHTMLWithHoverPopUps
                                    contentHTML={overview.childMarkdownRemark.html}
                                    popUpItems={definedTermsPopUpItems}
                                    className="content"
                                />
                                <ShareButtons url={location.href} title={`Check out our event: ${pageContext.title}`} />
                                <Button textClass="size-20" type="outlined" text="contact us" color="purple" to="/ask-us-anything" />
                            </div>

                            {getDesktopCTAContainer()}

                        </div>
                    </div>
                </section>

                {(agendaList || freeText || quotes) && (
                    <section className="section">
                        <div className="container">
                            {agendaList && (
                                <h2 className="has-text-centered font-variable size-56 is-transparent text-stroke-black">
                                    agenda
                                </h2>
                            )}

                            <div className="columns padding-y-40">
                                <div className="column is-10 is-offset-1">

                                    {agendaList && (
                                        <div className="columns is-multiline is-hidden-mobile">
                                            {agendaList.map((agenda, i) => (
                                                <div className="column is-4" key={i}>
                                                    <div className="columns">
                                                        <div className="column is-2">
                                                            <img src={getAgendaImage(i)} alt="" />
                                                        </div>
                                                        <div className="column is-10">
                                                            {agenda}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {agendaList && (
                                        <div className="columns is-multiline is-hidden-tablet">
                                            {agendaList.map((agenda, i) => (
                                                <div className="column is-12" key={i}>
                                                    <div className="columns is-mobile">
                                                        <div className="column is-2">
                                                            <img src={getAgendaImage(i)} />
                                                        </div>
                                                        <div className="column is-10">
                                                            {agenda}
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}

                                    {freeText !== null && (
                                        <ContentHTMLWithHoverPopUps
                                            contentHTML={freeText.childMarkdownRemark.html}
                                            popUpItems={definedTermsPopUpItems}
                                            className="content"
                                        />
                                    )}

                                    {quotes && (
                                        <div className="columns is-multiline">
                                            {quotes.map((quote, i) => (
                                                <div className="column is-4" key={i}>
                                                    <div className="card is-purple-tertiary-bg has-text-centered" style={{ borderRadius: "75px" }}>
                                                        <div className="card-content content">
                                                            <img src={white_transparent_blockquote_start} alt="" />
                                                            <p className="is-black size-16">
                                                                {quote.quote.quote}
                                                            </p>
                                                            <span className="font-medium is-black size-16"><strong>{quote.customerTitle}</strong></span><br />
                                                            <p className="font-medium is-grey-1 size-16">{quote.customerName}</p>
                                                            <img src={white_transparent_blockquote_end} alt="" />
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                {pageLinkLists !== null && (
                    <section className={`section is-medium`}>
                        <div className="container content">
                            <div className="columns">
                                <div className="column is-three-fifths is-offset-one-fifth">
                                    <PageLinkList lists={pageLinkLists} header={pageLinkListHeader} />
                                </div>
                            </div>
                        </div>
                    </section>
                )}

                <img height="1" width="1" style={{ display: "none" }} alt="" src="https://px.ads.linkedin.com/collect/?pid=2037017&conversionId=4025513&fmt=gif" />
            </main>
        </Layout >
    );
}

export const query = graphql`
query ($contentful_id: String!, $contentful_content_vocabulary_item_slugs: [String]) {
    contentfulEvent (contentful_id: { eq: $contentful_id }) {
        pageLinkListHeader
        pageLinkLists {
            linkList {
              ... on ContentfulWebinarV2 {
                __typename
                id
                title
                webinarUrl
                webinarFreeText {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                webinarImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulServicesSubpage {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulLanding {
                __typename
                id
                title
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCustomerType {
                __typename
                id
                name
                slug
                ingress {
                  childMarkdownRemark {
                    excerpt
                  }
                }
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
              ... on ContentfulCustomer {
                __typename
                id
                name
                description
                slug
                heroImage {
                  gatsbyImageData(height: 400, width: 400)
                }
              }
              ... on ContentfulCoSellPartner {
                __typename
                id
                name
                slug
                slogan
              }
              ... on ContentfulArticle {
                __typename
                id
                title
                articleUrl
                subtitle
                heroImage {
                  gatsbyImageData(width: 400, height: 400)
                }
              }
            }
            title
          }
        metaTags
        createdAt
        updatedAt
        title
        text
        url
        ctaText
        speakers {
            title
            name
            linkedInPage
            portrait {
                gatsbyImageData(width: 90, quality: 100)
            }
        }
        quotes {
            customerTitle
            customerName
            quote {
                quote
            }
        }
        overview {
            childMarkdownRemark {
                html
            }
        }
        language
        freeText {
            childMarkdownRemark {
            html
            }
        }
        registrationHubspotFormId
        registrationExternalFormUrl
        recordingVimeoUrl
        startTime
        endTime
        location
        agendaList
        image {
            file {
                url
            }
        }
    }
    bg: file(relativePath: { eq: "images/purple_blurry_bg.jpg" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          gatsbyImageData(width: 1920, quality: 100)
        }
    }
    allContentfulDefinedTerm(
        filter: {slug: {in: $contentful_content_vocabulary_item_slugs}, node_locale: {eq: "en-US"}}
        ) {
        nodes {
            slug
            title
            description {
                childMarkdownRemark {
                    html
                    excerpt
                }
            }
        }
    }
}
`